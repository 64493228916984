import React from 'react';
import { Link } from 'gatsby';
import { Button } from '@periodica/ui-kit';
import cn from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';

import { RubleSign } from '../RubleSign';
import { serviceType } from '../../prop-types/service';

import styles from './ServiceBlock.module.scss';

export function ServiceBlock({
  service: { title, subtitle, info, price, url, image, externalUrl },
}) {
  return externalUrl ? (
    <a
      href={url}
      className={cn({
        [styles.block]: true,
      })}
    >
      <GatsbyImage
        alt={title}
        className={styles.image}
        image={image.localFile.childImageSharp.gatsbyImageData}
      />
      <div className={styles.metadata}>
        <div className={styles.info}>
          <h3 className={styles.title}>{title}</h3>
          {price && !subtitle ? (
            <h4 className={styles.subtitle}>
              {price}&nbsp;
              <RubleSign />
            </h4>
          ) : (
            <h4 className={styles.subtitle}>{subtitle}</h4>
          )}
          <div className={styles.serviceInfo} dangerouslySetInnerHTML={{ __html: info }} />
        </div>
      </div>
      <button type="button" className={styles.serviceButton}>
        Заказать услугу
      </button>
    </a>
  ) : (
    <Link
      to={url}
      className={cn({
        [styles.block]: true,
      })}
    >
      <GatsbyImage
        alt={title}
        className={styles.image}
        image={image.localFile.childImageSharp.gatsbyImageData}
      />
      <div className={styles.metadata}>
        <div className={styles.info}>
          <h3 className={styles.title}>{title}</h3>
          {price && !subtitle ? (
            <h4 className={styles.subtitle}>
              {price}&nbsp;
              <RubleSign />
            </h4>
          ) : (
            <h4 className={styles.subtitle}>{subtitle}</h4>
          )}
          <div className={styles.serviceInfo} dangerouslySetInnerHTML={{ __html: info }} />
        </div>
      </div>
      <Button type="button" variant="secondary">
        Узнать больше
      </Button>
    </Link>
  );
}

ServiceBlock.propTypes = {
  service: serviceType.isRequired,
};
