import React from 'react';

import { Link } from '@reach/router';
import { Button } from '@periodica/ui-kit';

import Images from '@components/Images';

import styles from './Review.module.scss';

interface ReviewArgs {
  name: string;
  photo: string;
  bookUrl: string;
  children: React.ReactNode;
}

export function Review({ name, photo, bookUrl, children }: ReviewArgs) {
  return (
    <div className={styles.reviewBlock}>
      <div className={styles.reviewBlock__pic}>
        <Images className={styles.img} src={photo} alt={`${name} img`} />
      </div>
      <p className={styles.reviewBlock__name}>{name}</p>
      <div className={styles.reviewBlock__text}>{children}</div>
      <Button size="medium" asChild>
        <Link to={bookUrl}>Заказать эту книгу</Link>
      </Button>
    </div>
  );
}
