import React from 'react';
import { Link } from 'gatsby';
import { Button } from '@periodica/ui-kit';

import Images from '@components/Images';

import styles from './PromoBlock.module.scss';

interface PromoBlock {
  promoImage: string;
  promoTitle: string;
  promoText: string;
  promoLink: string;
}

export function PromoBlock({ promoImage, promoTitle, promoText, promoLink }: PromoBlock) {
  return (
    <div className={styles.aboutContainer}>
      <Images src={promoImage} className={styles.image} alt="About" />
      <div className={styles.aboutTextContainer}>
        <div className={styles.aboutText}>
          <h2>{promoTitle}</h2>
          <p dangerouslySetInnerHTML={{ __html: promoText }} />
          <Button size="xLarge" asChild>
            <Link to={promoLink}>Подробнее</Link>
          </Button>
        </div>
      </div>
    </div>
  );
}
